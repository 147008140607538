<template>
  <v-dialog
    v-model="updateShieldSymbolModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar Símbolo
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="updateShieldSymbol()"
        >
          <image-uploader
            title="Clique aqui para enviar uma imagem"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :savedImage="olderImage"
            :errorImage="imageOffIcon"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updateShieldSymbol()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    ImageUploader: () => import('../../../components/ImageUploader.vue')
  },
  props: {
    symbolData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imageOffIcon: imageOffIcon,
      saving: false,
      id: null,
      symbol: {
        imageType: undefined
      },
      olderImage: '',
      image: null
    }
  },
  computed: {
    updateShieldSymbolModal: {
      get () {
        return this.$store.state.updateShieldSymbolModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateShieldSymbolModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateShieldSymbolModal (val) {
      if (val) {
        const { _id, image } = this.symbolData
        this.id = _id
        this.olderImage = image
      } else {
        this.symbol = {
          imageType: undefined
        }
        this.id = null
        this.olderImage = ''
      }
    }
  },
  methods: {
    updateShieldSymbol () {
      if (this.image || this.symbol.imageType) {
        this.saving = true
        this.$http.put(`/shield-symbols/${this.id}`, this.symbol)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      } else {
        this.$toast.warning('Não há nenhuma alteração para ser salva.')
      }
    },
    success () {
      this.saving = false
      this.updateShieldSymbolModal = false
      this.$emit('updated')
      this.$toast.success('Cadastro atualizado com sucesso.')
    },
    error () {
      this.saving = false
      this.updateShieldSymbolModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.symbol.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.symbol.imageType = undefined
      this.olderImage = null
      this.image = null
    }
  }
}
</script>
